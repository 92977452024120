<template>
    <ais-hierarchical-menu
        class="algolia-category-list"
        :attributes="[
            'categories.level0',
            'categories.level1',
            'categories.level2',
            'categories.level3'
        ]"
        :limit="30"
        :transform-items="transformItems"
    >
        <template
            v-slot="{
              items,
              refine/*,
              canToggleShowMore,
              isShowingMore,
              toggleShowMore,
              createURL,
              sendEvent,*/
            }"
        >
            <HCard
                class="tree-menu"
                type="free"
            >
                <HTreeview
                    class="tree-view"
                    activatable
                    hoverable
                    rounded
                    :active="treeValue"
                    @update:active="(treeNodes) => onItemActive(treeNodes, refine)"
                    :items="items"
                ></HTreeview>
            </HCard>

        </template>
    </ais-hierarchical-menu>
</template>

<script>
import {
    AisHierarchicalMenu
} from 'vue-instantsearch'

import {
    HCard,
    HTreeview
} from '@happytal/bo-ui-library'

import Routes from '@/constants/routes'

export default {
    name: 'AlgoliaCategoryList',
    components: {
        AisHierarchicalMenu,

        HCard,
        HTreeview
    },
    data: () => ({
        _nodes: [],
        nodeSelected: null,
        treeValue: ['home']
    }),
    methods: {
        onItemActive (nodes, refine) {
            const node = this.getNodeById(nodes[0], this._nodes)
            if (node) {
                this.$emit('change', node.id)
                if (this.nodeSelected != node.id) {
                    this.nodeSelected = node.id

                    if (node.id == 'home') {
                        this.$router.push({
                            name: Routes.CatalogExplorer
                        })
                        return
                    }
                    refine(node.value)
                }
            }
        },
        getNodeById (id, nodes) {
            for (let n=0; n<nodes.length; n++) {
                const node = nodes[n]
                if (node.id == id) {
                    return node
                }
                const children = node.children
                if (children && children.length > 0) {
                    const result = this.getNodeById(id, children)
                    if (result) {
                        return result
                    }
                }
            }
            return null
        },
        getNodesFromItems (items) {
            return items.map((item) => {
                const node = {
                    id: item.value,
                    name: item.label,
                    value: item.value,
                    children: []
                }
                if (_.get(item, 'data.length', 0) > 0) {
                    node.children = this.getNodesFromItems(item.data)
                }
                return node
            })
        },
        transformItems (items) {
            this._nodes = this.getNodesFromItems(items)
            this._nodes.unshift({
                id: 'home',
                name: 'Catalogue',
                value: undefined,
                children: []
            })
            return this._nodes
        },
        scrollToTop () {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }, 500)
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';
.algolia-category-list {

    .tree-menu {
        width: 320px;
        max-height: 500px;
        padding: 20px;
        overflow-y: auto;

        @media (max-width: map-get($breakpoints, sm)) {
            width: auto;
        }

        .tree-view::v-deep {

            .v-treeview-node__root {
                cursor: pointer;
            }

            button {
                background-color: transparent !important;
            }
        }
    }
}
</style>
