var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ais-hierarchical-menu',{staticClass:"algolia-category-list",attrs:{"attributes":[
        'categories.level0',
        'categories.level1',
        'categories.level2',
        'categories.level3'
    ],"limit":30,"transform-items":_vm.transformItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var items = ref.items;
        var refine = ref.refine;
return [_c('HCard',{staticClass:"tree-menu",attrs:{"type":"free"}},[_c('HTreeview',{staticClass:"tree-view",attrs:{"activatable":"","hoverable":"","rounded":"","active":_vm.treeValue,"items":items},on:{"update:active":function (treeNodes) { return _vm.onItemActive(treeNodes, refine); }}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }