<template>
    <div id="catatog-explorer" class="catalog-explorer">

        <AlgoliaInstantSearch
            class="global"
        >
            <div class="catalog">

                <div class="catalog-menu">
                    <div class="header">
                        <div class="logo">
                            <img class="image" src="@/assets/images/ehpad/logo_happydom.png" />
                        </div>
                        <AlgoliaRefinementListMask
                            attribute="institutions"
                        />
                        <AlgoliaCategoryList
                            @change="onCategoryChange"
                        />
                    </div>
                </div>

                <div class="catalog-items">
                    <div class="search-header">
                        <AlgoliaAutocomplete
                            ref="autocomplete"
                            class="search-box"
                            placeholder="Rechercher un produit ou service"
                            :externalSearch="false"
                            :globalSearch="true"
                        />
                        <AlgoliaSortBy
                            class="sort-by"
                            label="Trier par"
                            :sortItems="sortItems"
                        />
                    </div>
                    <div class="content">
                        <div
                            v-show="(isMobile && filterOpened) || !isMobile"
                            class="filter-modal"
                            :class="{ 'filter-modal-open': filterOpened }"
                        >
                            <div class="modal-header">
                                <HBtn
                                    class="back-btn"
                                    icon="ArrowLeftIcon"
                                    icon-only
                                    style-type="tertiary"
                                    @click="onFilterCloseBtnClick"
                                ></HBtn>
                                <div class="title">Filtres</div>
                            </div>
                            <div class="modal-content">
                                <div class="filters">
                                    <div class="filter-header">Filtres</div>
                                    <AlgoliaCheckboxList
                                        class="filter"
                                        attribute="brandLabel"
                                        placeholder="Rechercher une marque"
                                        label="Marques"
                                    />
                                    <AlgoliaToggleList
                                        class="filter"
                                        attribute="size"
                                        label="Tailles"
                                    />
                                    <AlgoliaColorList
                                        class="filter"
                                        attribute="color"
                                        label="Couleurs"
                                    />
                                    <AlgoliaRange
                                        class="filter prices"
                                        attribute="price"
                                        label="Prix"
                                    />
                                </div>
                            </div>
                            <AlgoliaResetBtn
                                v-if="!isMobile"
                                class="reset-filters-btn"
                                :excluded-attributes="['categories.level0', 'institutions']"
                                label="Réinitialiser les filtres"
                            />
                            <div
                                v-show="filterOpened"
                                class="modal-footer"
                            >
                                <div class="action-bar">
                                    <AlgoliaResetBtn
                                        v-if="isMobile"
                                        class="mobile-reset-btn"
                                        label="Réinitialiser"
                                        :excluded-attributes="['categories.level0', 'institutions']"
                                        :customized="true"
                                    />
                                    <HBtn
                                        class="apply-btn"
                                        style-type="secondary"
                                        @click="onApplyBtnClick"
                                    >Appliquer</HBtn>
                                </div>
                            </div>
                        </div>
                        <div class="results">
                            <AlgoliaStats />
                            <ais-hits
                                :transform-items="transformItems"
                            >
                                <template v-slot="{ items }">
                                    <div
                                        v-if="items.length < 1"
                                        class="no-results"
                                    >
                                        Nous sommes désolés, il n’y a pas de résultat pour votre recherche.
                                    </div>
                                    <HRow :gap="3">
                                        <HCol
                                            v-for="product in items"
                                            :key="product.objectID"
                                            :cols="12"
                                            :colsSm="6"
                                            :colsMd="4"
                                            :colsLg="3"
                                        >
                                            <ProductCard
                                                :product="product"
                                            />
                                        </HCol>
                                    </HRow>
                                    <div
                                        v-show="isMobile && !filterOpened"
                                        class="filter-bar"
                                    >
                                        <HBtn
                                            class="filter-btn"
                                            icon="ChevronUpIcon"
                                            rounded
                                            @click="onFilterOpenBtnClick"
                                        >Filtres</HBtn>
                                    </div>
                                 </template>
                            </ais-hits>
                            <AlgoliaPagination
                                @change="onPageChange"
                            />
                        </div>
                    </div>
                </div>
            </div>

        </AlgoliaInstantSearch>

        <div class="footer">
            COPYRIGHT ©{{ currentYear }}
            <a href="https://www.happytal.com" target="_blank" rel="nofollow">happytal</a>,
            Tous droits réservés.
        </div>

        <BtnScrollToTop />
    </div>
</template>

<script>
    import _ from 'lodash'
    import moment from 'moment'
    import {
        AisHits
    } from 'vue-instantsearch'
    import Oauth from "@/oauth"
    import Http from '@/http'
    import appsettings from '@/appsettings'

    import Routes from '@/constants/routes'

    import catalogMixins from './catalog.mixins'

    import { HCard, HBtn, HRow, HCol, HSelect } from '@happytal/bo-ui-library'
    import BtnScrollToTop from '@/components/BtnScrollToTop'

    import ProductCard from './components/ProductCard'
    import AlgoliaInstantSearch from './components/AlgoliaInstantSearch'
    import AlgoliaRefinementListMask from './components/AlgoliaRefinementListMask'
    import AlgoliaCategoryList from './components/AlgoliaCategoryList'
    import AlgoliaCheckboxList from './components/AlgoliaCheckboxList'
    import AlgoliaToggleList from './components/AlgoliaToggleList'
    import AlgoliaColorList from './components/AlgoliaColorList'
    import AlgoliaRange from './components/AlgoliaRange'
    import AlgoliaPagination from './components/AlgoliaPagination'
    import AlgoliaAutocomplete from './components/AlgoliaAutocomplete'
    import AlgoliaResetBtn from './components/AlgoliaResetBtn'
    import AlgoliaSortBy from './components/AlgoliaSortBy'
    import AlgoliaStats from './components/AlgoliaStats'

    const vueAppAlgoliaSortItems = appsettings.VUE_APP_ALGOLIA_SORT_ITEMS

    export default {
        mixins: [
            catalogMixins
        ],
        components: {
            BtnScrollToTop,

            HRow,
            HCol,
            HCard,
            HBtn,
            HSelect,

            AisHits,

            AlgoliaInstantSearch,
            AlgoliaRefinementListMask,
            AlgoliaCategoryList,
            AlgoliaCheckboxList,
            AlgoliaToggleList,
            AlgoliaColorList,
            AlgoliaRange,
            AlgoliaPagination,
            AlgoliaAutocomplete,
            AlgoliaResetBtn,
            AlgoliaSortBy,
            AlgoliaStats,

            ProductCard
        },
        data () {
            return {
                sortItems: JSON.parse(vueAppAlgoliaSortItems),
                filterOpened: false
            }
        },
        computed: {
            isMobile () {
                return this.$breakpoint.smAndDown
            },
            currentYear () {
                return moment().year()
            }
        },
        methods: {
            transformItems (items) {
                return items.map((item) => {
                    return {
                        id: item.objectID,
                        variantCode: item.variantCode,
                        title: item.title,
                        imageUrl: item.image,
                        brand: item.brandLabel,
                        price: item.price,
                        quantity: item.formFactor,
                        route: {},
                        type: item.type
                    }
                })
            },
            onFilterOpenBtnClick (e) {
                this.filterOpened = true
            },
            onFilterCloseBtnClick (e) {
                this.filterOpened = false
            },
            onApplyBtnClick (e) {
                this.filterOpened = false
            },
            onPageChange () {
                this.scrollToTop()
            },
            onCategoryChange (categoryId) {
                if (categoryId == 'home') {
                    this.$refs.autocomplete.reset()
                }
            },
            scrollToTop () {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
@import '~@happytal/bo-ui-library/src/styles/variables';

.catalog-explorer {

    .global {
        padding: 30px 30px 30px 30px;

        .catalog {
            display: flex;

            @media (max-width: map-get($breakpoints, sm)) {
                flex-wrap: wrap;
            }

            .catalog-menu {
                flex-shrink: 0;
                width: 320px;
                margin: 0px 0px 0px 0px;

                @media (max-width: map-get($breakpoints, sm)) {
                    width: 100%;
                    margin: 0px 0px 20px 0px;
                }

                .header {
                    position: fixed;

                    @media (max-width: map-get($breakpoints, sm)) {
                        position: relative;
                    }

                    .logo {
                        height: 50px;
                        margin: 0px 0px 30px 0px;

                        @media (max-width: map-get($breakpoints, sm)) {
                            text-align: center;
                        }

                        .image {
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
            }
            .catalog-items {
                flex-grow: 1;
                margin: 0px 0px 0px 30px;

                @media (max-width: map-get($breakpoints, sm)) {
                    margin: 0px 0px 0px 0px;
                }

                .search-header {
                    display: flex;
                    align-items: center;
                    margin: 5px 0px 0px 0px;

                    @media (max-width: map-get($breakpoints, sm)) {
                        flex-wrap: wrap;
                    }

                    .search-box {
                        flex-grow: 1;

                        ::v-deep .aa-Autocomplete {
                            width: 100%;
                        }
                    }
                    .sort-by {
                        margin: 0px 0px 0px 30px;
                        z-index: 2;
                    }
                }
                .reset-btn {

                }
                .content {
                    display: flex;

                    .filter-modal {
                        width: 260px;

                        .reset-filters-btn {
                            border-top: 1px solid #EEEEEE;
                        }
                        .modal-header {
                            display: none;

                            .title {

                            }
                        }
                        .modal-content {

                            .filters {
                                width: 260px;
                                flex-shrink: 0;

                                .filter-header {
                                    padding: 20px 0px 20px 0px;
                                    font-weight: 700;
                                    font-size: 16px;
                                    line-height: 20px;
                                    color: #000000;
                                }
                                .filter {

                                }
                            }
                        }
                        .modal-footer {

                            .action-bar {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                height: 54px;
                                background-color: var(--v-primary-base);

                                .reset-btn {
                                    border: 2px solid white;
                                }
                                .apply-btn {
                                    margin: 0px 0px 0px 20px;
                                    border: 2px solid white;
                                    background: white;
                                }
                            }
                        }
                    }
                    .filter-modal-open {
                        display: flex;
                        flex-direction: column;
                        position: fixed;
                        left: 0px;
                        top: 0px;
                        right: 0px;
                        bottom: 0px;
                        z-index: 15;
                        width: auto;

                        .modal-header {
                            display: flex;
                            align-items: center;
                            flex-shrink: 0;
                            height: 54px;
                            background: white;
                            padding: 0px 20px 0px 20px;
                            border-bottom: 1px solid #EEEEEE;
                            box-shadow: 0 0 8px 0 rgba(0,0,0,.1);

                            .title {
                                margin: 0px 0px 0px 15px;
                                font-weight: 600;
                                font-size: 17px;
                                line-height: 20px;
                                text-transform: uppercase;
                                color: black;
                            }
                        }
                        .modal-content {
                            height: calc(100% - 108px);
                            overflow-y: auto;
                            background: white;

                            .filters {
                                flex-grow: 1;
                                width: auto;

                                @media (max-width: map-get($breakpoints, sm)) {
                                    padding: 0px 0px 20px 0px;
                                }

                                .filter-header {
                                    display: none;
                                }
                                .filter {
                                    padding: 0px 20px 0px 20px;
                                }
                            }
                        }
                        .modal-footer {
                            flex-shrink: 0;
                            height: 54px;
                        }
                    }
                    .results {
                        flex-grow: 1;
                        margin: 20px 0px 20px 40px;

                        @media (max-width: map-get($breakpoints, sm)) {
                            margin: 20px 0px 20px 0px;
                        }

                        .products {

                            .no-results {
                                margin: 30px 0px 30px 0px;
                                font-weight: 500;
                                font-size: 17px;
                                line-height: 20px;
                                text-align: center;
                                color: black;
                            }
                        }
                        .filter-bar {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 54px;
                            position: fixed;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;
                            z-index: 2;

                            .filter-btn {

                            }
                        }
                        .pagination {

                        }
                    }
                }
            }
        }
    }

    .footer {
        padding: 0px 30px 30px 30px;
        text-align: center;
        font-size: 14px;
    }
}
</style>
